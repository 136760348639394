export const showErrorMessage = message => ({
  type: "SHOW_ERROR_MESSAGE",
  payload: message
});

export const showSuccessMessage = message => ({
  type: "SHOW_SUCCESS_MESSAGE",
  payload: message
});

export const showInfoMessage = message => ({
  type: "SHOW_INFO_MESSAGE",
  payload: message
});

export const setBackdropAction = backdrop => {
  return {
    type: "SET_BACKDROP",
    payload: backdrop
  };
};

export const setCategoriaAction = categoria => {
  return {
    type: "SET_CATEGORIA",
    payload: categoria
  };
};

export const setBackdrop = (backdrop, id) => {
  const payload = { open: backdrop, id };
  return dispatch => {
    dispatch(setBackdropAction(payload));
  };
};
