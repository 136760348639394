import React from "react";
import { useDispatch } from "react-redux";
import { setCategoriaAction } from "../../redux/actions";

export const Carrusel = ({ items }) => {
  const dispatch = useDispatch();
  return (
    <div
      id="myCarousel"
      className="carousel slide banner-main"
      data-ride="carousel"
    >
      <ul className="carousel-indicators">
        {items.map((x, index) => (
          <li
            data-target="#myCarousel"
            data-slide-to={index}
            className={index == 0 ? "active" : ""}
          ></li>
        ))}
      </ul>
      <div className="carousel-inner">
        {items.map((x, index) => (
          <div className={`carousel-item ${index == 0 ? "active" : ""}`}>
            <img class="img-slider" src={x.imagenPath} alt={x.titulo} />
            <div className="container">
              <div className="carousel-caption relative">
                <h1>{x.titulo}</h1>
                <span>{x.subtitulo}</span>

                <p>{x.descripcion}</p>
                {x.categoriaId && (
                  <a
                    className="buynow"
                    onClick={() => {
                      dispatch(setCategoriaAction(x.categoriaId));
                    }}
                  >
                    Ver productos
                  </a>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <a
        className="carousel-control-prev"
        href="#myCarousel"
        role="button"
        data-slide="prev"
      >
        <i className="fa fa-angle-left"></i>
      </a>
      <a
        className="carousel-control-next"
        href="#myCarousel"
        role="button"
        data-slide="next"
      >
        <i className="fa fa-angle-right"></i>
      </a>
    </div>
  );
};

export default Carrusel;
