import React, { useRef, useState } from "react";
import { TextField, Button } from "@mui/material";
import "./../../styles/agrobolsas.scss";
import { useRequestHandler } from "../../utils/useRequestHandler";
import { BackendApi, Login } from "../../services/HttpBaseService";
import { ImExit } from "react-icons/im";
import CarruselABM from "./carruselABM";
import CategoriaABM from "./categoriaABM";
import ProductoABM from "./productoABM";
import { showErrorMessage } from "../../redux/actions";
import { useDispatch } from "react-redux";
import Password from "./password";
import { useNavigate } from "react-router-dom";

const Admin = () => {
  document.title = "Agro Bolsas - Administración";
  const passwordRef = useRef();
  const [logueado, setLogueado] = useState(
    localStorage.getItem("token") != null
  );
  const [opcionMenu, setOpcionMenu] = useState(1);
  const dispatch = useDispatch();
  const sendRequest = useRequestHandler();
  const navigate = useNavigate();
  const handleLogin = password => {
    sendRequest(
      Login,
      [password],
      res => {
        if (Boolean(res.data) == true) {
          setLogueado(res.data);
          localStorage.setItem("token", res.data.token);
          BackendApi.defaults.headers.common["Authorization"] = `Basic ${btoa(
            `admin:${password}`
          )}`;
        }
      },
      err => {
        if (err?.response?.status == 401)
          dispatch(showErrorMessage("Contraseña incorrecta"));
      }
    );
  };

  return (
    <div className="admin">
      <div className="admin-content">
        <header>
          <div className="header">
            <div className="container">
              <div className="row">
                <div className="col-2">
                  <a href="/">
                    <img
                      style={{ width: "180px" }}
                      src="images/logo.png"
                      alt="logo agro bolsas"
                    />
                  </a>
                </div>
                {logueado && (
                  <div className="col text-center">
                    <div class="title">
                      <h2>Gestión interna</h2>
                    </div>
                  </div>
                )}
                {logueado && (
                  <div className="col-2 text-right">
                    <Button
                      className="m-2 p-2 btn btn-salir"
                      onClick={() => {
                        localStorage.removeItem("token");
                        delete BackendApi.defaults.headers.common[
                          "Authorization"
                        ];
                        setLogueado(false);
                        navigate("/");
                      }}
                    >
                      <ImExit className="mx-1" /> Salir
                    </Button>
                  </div>
                )}
                {logueado && logueado && (
                  <>
                    <div className="row w-100">
                      <div className="col-md-12 location_icon_bottum">
                        <div className="row">
                          <div className="col-md-8">
                            <div className="menu-area">
                              <div className="limit-box">
                                <nav className="main-menu">
                                  <ul className="menu-area-main">
                                    <li>
                                      <a
                                        onClick={() => setOpcionMenu(1)}
                                        className={` dato-contacto-header${
                                          opcionMenu == 1 ? " active" : ""
                                        }`}
                                      >
                                        Carrusel
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        onClick={() => setOpcionMenu(2)}
                                        className={` dato-contacto-header${
                                          opcionMenu == 2 ? " active" : ""
                                        }`}
                                      >
                                        Categorías
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        onClick={() => setOpcionMenu(3)}
                                        className={` dato-contacto-header${
                                          opcionMenu == 3 ? " active" : ""
                                        }`}
                                      >
                                        Productos
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        onClick={() => setOpcionMenu(4)}
                                        className={` dato-contacto-header${
                                          opcionMenu == 4 ? " active" : ""
                                        }`}
                                      >
                                        Cambiar contraseña
                                      </a>
                                    </li>
                                  </ul>
                                </nav>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </header>
        {!logueado ? (
          <>
            <div class="container pt-4">
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <div class="title">
                      <h2>
                        Acceso <strong class="black">administrador</strong>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex row mb-4 justify-content-around">
              <div className="col-auto text-center row">
                <div className="col">
                  <TextField
                    onKeyUp={e => {
                      if (e.key === "Enter") {
                        handleLogin(passwordRef.current.value);
                      }
                    }}
                    inputRef={passwordRef}
                    type="password"
                    className="text-field"
                    label="Password"
                  />
                </div>

                <div className="col-auto btn-search p-0 mx-2">
                  <Button
                    className="p-2"
                    onClick={() => {
                      handleLogin(passwordRef.current.value);
                    }}
                  >
                    ACCEDER
                  </Button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="container">
            {opcionMenu == 1 ? (
              <CarruselABM />
            ) : opcionMenu == 2 ? (
              <CategoriaABM />
            ) : opcionMenu == 3 ? (
              <ProductoABM />
            ) : opcionMenu == 4 ? (
              <Password />
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
      <div className="footer mt-4">
        <div className="w-100 text-center">v. 1.0.1</div>
      </div>
    </div>
  );
};
export default Admin;
