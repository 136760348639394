import { useDispatch } from "react-redux";
import { setBackdrop, showErrorMessage } from "../redux/actions";
import uuid from "react-uuid";

export const useRequestHandler = () => {
  const dispatch = useDispatch();

  const sendRequest = (promesa, params, onSuccessFn, onErrorFn) => {
    const backdropId = uuid();
    promesa && dispatch(setBackdrop(true, backdropId));
    promesa?.(...params)
      .then(data => onSuccessFn?.(data))
      .catch(err => {
        if (err?.response?.status == 422) {
          dispatch(showErrorMessage(err?.response?.data?.message));
        } else {
          if (!onErrorFn) {
            dispatch(
              showErrorMessage(
                "Ocurrió un error al intentar procesar la solicitud"
              )
            );
            console.log(err);
          }
        }
        onErrorFn?.(err);
      })
      .finally(() => dispatch(setBackdrop(false, backdropId)));
  };

  return sendRequest;
};
