import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import Loading from "./Loading";

export default function() {
  const { open: showBackdrop, message } = useSelector(store => store.backdrop);
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: 100000 }}
      open={showBackdrop ?? false}
    >
      <div className="loading-backdrop">
        <div>
          <img src="images/loading.gif" alt="loading agro bolsas" />
        </div>
        {message && <div className="text-white mt-3">{message}</div>}
      </div>
    </Backdrop>
  );
}
