import React, { useEffect, useState } from "react";
import { TextField, Button, FormControlLabel, Switch } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch } from "react-redux";
import { useRequestHandler } from "../../utils/useRequestHandler";
import {
  CrearCategoria,
  EliminarCategoria,
  ModificarCategoria,
  ObtenerCategorias
} from "../../services/HttpBaseService";

export const CategoriaABM = () => {
  const [elementos, setElementos] = useState([]);
  const [agregando, setAgregando] = useState(false);
  const [nombre, setNombre] = useState("");
  const [editando, setEditando] = useState(false);
  const [idEditando, setIdEditando] = useState(0);
  const [orden, setOrden] = useState(0);
  const [activo, setActivo] = useState(false);
  const sendRequest = useRequestHandler();
  const dispatch = useDispatch();

  const refrescarGrilla = () => {
    sendRequest(ObtenerCategorias, [], data => {
      setElementos(data?.data);
    });
  };

  const limpiarForm = () => {
    setNombre("");
    setOrden(0);
    setActivo(false);
  };

  useEffect(() => {
    refrescarGrilla();
  }, []);

  const eliminarCategoria = id => {
    sendRequest(EliminarCategoria, [id], refrescarGrilla);
  };

  const columns = [
    {
      field: "actionDelete",
      headerName: "",
      sortable: false,
      flex: 1,
      align: "right",
      renderCell: params => {
        const onClick = e => {
          if (window.confirm("¿Confirmás eliminar la categoría?")) {
            eliminarCategoria(params?.row?.id);
          }
          refrescarGrilla();
        };

        return (
          <DeleteIcon
            size={28}
            className="action-icon"
            title="Eliminar"
            onClick={onClick}
          />
        );
      }
    },
    {
      field: "actionEdit",
      headerName: "",
      sortable: false,
      flex: 1,
      align: "right",
      renderCell: params => {
        const onClick = e => {
          setAgregando(true);
          setEditando(true);
          setIdEditando(params.row.id);
          setNombre(params.row.nombre);
          setActivo(params.row.activo);
          setOrden(params.row.orden);
        };

        return (
          <EditIcon
            size={28}
            className="action-icon"
            title="Editar"
            onClick={onClick}
          />
        );
      }
    },
    { field: "orden", headerName: "Orden", flex: 5 },
    { field: "nombre", headerName: "Nombre", flex: 80 }
  ];

  const handleGuardar = () => {
    if (editando) {
      sendRequest(
        ModificarCategoria,
        [
          {
            id: idEditando,
            nombre,
            activo,
            orden: parseInt(orden)
          }
        ],
        data => {
          limpiarForm();
          setEditando(false);
          setAgregando(false);
          refrescarGrilla();
        }
      );
    } else {
      sendRequest(
        CrearCategoria,
        [
          {
            nombre,
            activo,
            orden: parseInt(orden)
          }
        ],
        () => {
          limpiarForm();
          setEditando(false);
          setAgregando(false);
          refrescarGrilla();
        }
      );
    }
  };

  return (
    <>
      <>
        {!agregando ? (
          <>
            <div className="d-flex row m-2 justify-content-between">
              <div className="col-4">
                <h2>Categorías de productos</h2>
              </div>

              <div>
                <Button
                  variant={"contained"}
                  onClick={() => {
                    setAgregando(true);
                  }}
                >
                  Agregar
                </Button>
              </div>
            </div>
            <hr></hr>
            <div
              className="row m-2 justify-content-center"
              style={{ width: "100%" }}
            >
              {elementos?.length > 0 ? (
                <DataGrid
                  hideFooterPagination
                  rows={elementos}
                  columns={columns}
                  paginationModel={{ page: 0, pageSize: 50 }}
                  checkboxSelection={false}
                />
              ) : (
                <h5 className="">No se encontraron resultados</h5>
              )}
            </div>
          </>
        ) : (
          <div className="justify-content-around ">
            <div className="d-flex row justify-content-between">
              <div className="col m-2">
                <h3>
                  {editando
                    ? "Modificar categoría de productos"
                    : "Nueva categoría de productos"}
                </h3>
              </div>
            </div>
            <hr></hr>
            <div className="justify-content-center w-auto d-flex row">
              <div className="col">
                <div className="col m-2">
                  <TextField
                    className="text-field"
                    size="large"
                    label="Nombre"
                    value={nombre}
                    onChange={e => setNombre(e.target.value)}
                  />
                </div>

                <div className="col m-2">
                  <TextField
                    type="number"
                    className="text-field"
                    size="large"
                    label="Orden"
                    value={orden}
                    onChange={e => setOrden(e.target.value)}
                  />
                </div>
                <div className="col m-2">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={activo}
                        onChange={e => setActivo(value => !value)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Activa"
                    labelPlacement="start"
                  />
                </div>
              </div>
            </div>
            <hr></hr>
            <div className="d-flex row justify-content-center w-auto">
              <div className="col text-left">
                <Button
                  variant={"outlined"}
                  onClick={() => {
                    setEditando(false);
                    setAgregando(false);
                    limpiarForm();
                  }}
                >
                  Cancelar
                </Button>
              </div>
              <div className="col text-right">
                <Button variant={"contained"} onClick={handleGuardar}>
                  Guardar
                </Button>
              </div>
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default CategoriaABM;
