import { Typography, capitalize } from "@mui/material";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const ProductoModal = ({ productoModal, show, handleClose }) => {
  const [imagenSeleccionada, setImagenSeleccionada] = useState(
    productoModal.imagenes?.[0]?.imagenPath
  );
  const [imagenSeleccionadaIndex, setImagenSeleccionadaIndex] = useState(0);

  const showNextImage = () => {
    const currentIndex = productoModal.imagenes.findIndex(
      img => img.imagenPath === imagenSeleccionada
    );
    const nextIndex =
      currentIndex === productoModal.imagenes.length - 1 ? 0 : currentIndex + 1;
    setImagenSeleccionada(productoModal.imagenes[nextIndex]?.imagenPath);
    setImagenSeleccionadaIndex(nextIndex);
  };

  const showPreviousImage = () => {
    const currentIndex = productoModal.imagenes.findIndex(
      img => img.imagenPath === imagenSeleccionada
    );
    const prevIndex =
      currentIndex === 0 ? productoModal.imagenes.length - 1 : currentIndex - 1;
    setImagenSeleccionada(productoModal.imagenes[prevIndex]?.imagenPath);
    setImagenSeleccionadaIndex(prevIndex);
  };
  return (
    <Modal
      dialogClassName="my-modal"
      show={show}
      onHide={handleClose}
      size={productoModal.descripcion ? "lg" : "none"}
    >
      <Modal.Header closeButton>
        <Modal.Title className="col">{productoModal.nombre}</Modal.Title>

        <MdClose
          style={{ cursor: "pointer" }}
          onClick={handleClose}
          size={24}
        />
      </Modal.Header>
      <Modal.Body>
        {productoModal.descripcion && (
          <div className="d-flex row modal-description-mobile">
            <Typography className="producto-categoria" component="div">
              <span>{capitalize(productoModal.categoriaNombre)}</span>
            </Typography>
            <div
              className="my-2"
              dangerouslySetInnerHTML={{
                __html: productoModal.descripcion
              }}
            />
          </div>
        )}
        <div className="d-flex row">
          <div
            className={
              productoModal.descripcion ? "col modal-img-viewer" : "col"
            }
          >
            <div className="modal-img-main">
              {imagenSeleccionadaIndex > 0 && (
                <div
                  onClick={showPreviousImage}
                  className="nav-button left-button"
                ></div>
              )}
              <img
                alt={productoModal.nombre}
                className="img-main image-transition"
                src={imagenSeleccionada ?? "images/no-image.jpg"}
              ></img>
              {imagenSeleccionadaIndex < productoModal.imagenes.length - 1 && (
                <div
                  onClick={showNextImage}
                  className="nav-button right-button"
                ></div>
              )}
            </div>

            <div className="modal-img-preview-wrapper">
              {productoModal.imagenes?.length > 0 &&
                productoModal.imagenes?.map((img, index) => (
                  <div
                    onClick={() => setImagenSeleccionada(img.imagenPath)}
                    className={`modal-img-preview ${
                      img.imagenPath == imagenSeleccionada ? "active" : ""
                    }`}
                  >
                    <img
                      src={img.imagenPath ?? "images/no-image.jpg"}
                      alt={productoModal.nombre}
                    ></img>
                  </div>
                ))}
            </div>
          </div>
          {productoModal.descripcion && (
            <div className="col modal-description">
              <Typography className="producto-categoria" component="div">
                <span>{capitalize(productoModal.categoriaNombre)}</span>
              </Typography>

              <div
                className="mt-4"
                dangerouslySetInnerHTML={{
                  __html: productoModal.descripcion
                }}
              />
            </div>
          )}
        </div>

        <br />
      </Modal.Body>
      <Modal.Footer className="justify-content-end">
        <a
          target="_blank"
          href={` https://wa.me/59898164065?text=${encodeURIComponent(
            `Hola *Agro Bolsas*, me interesa el producto ${productoModal.nombre}`
          )}`}
        >
          <Button className="buynow">Consultar</Button>
        </a>
      </Modal.Footer>
    </Modal>
  );
};

export default ProductoModal;
