import axios from "axios";

const GetBackendApi = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_BACKEND_API_URL,
    headers: {
      "Content-Type": "application/json"
    }
  };
  let instance = axios.create(defaultOptions);
  return instance;
};

export const BackendApi = GetBackendApi();

export const Login = async password => {
  return await BackendApi.get(`Login/Login?user=admin&password=${password}`);
};

export const ObtenerElementosCarrusel = async () => {
  return await BackendApi.get(`Carrusel`);
};

export const EliminarCarrusel = async id => {
  return await BackendApi.delete(`Carrusel/${id}`);
};

export const CrearCarrusel = async (carruselItem, imagen) => {
  const formData = new FormData();
  formData.append("imagen", imagen);
  const json = JSON.stringify(carruselItem);
  const blob = new Blob([json], {
    type: "application/json"
  });

  formData.append("carrusel", blob);
  return await BackendApi.post(`Carrusel`, formData, {
    baseURL: process.env.REACT_APP_BACKEND_API_URL,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const ModificarCarrusel = async (carruselItem, imagen) => {
  const formData = new FormData();
  formData.append("imagen", imagen);
  const json = JSON.stringify(carruselItem);
  const blob = new Blob([json], {
    type: "application/json"
  });

  formData.append("carrusel", blob);
  return BackendApi.put(`Carrusel`, formData, {
    baseURL: process.env.REACT_APP_BACKEND_API_URL,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const ObtenerCategorias = async () => {
  return await BackendApi.get(`Categoria`);
};

export const EliminarCategoria = async id => {
  return await BackendApi.delete(`Categoria/${id}`);
};

export const CrearCategoria = async categoria => {
  return await BackendApi.post(`Categoria`, categoria);
};

export const ModificarCategoria = async categoria => {
  return await BackendApi.put(`Categoria`, categoria);
};

export const ObtenerProductos = async (textoBusqueda = "", categoriaId = 0) => {
  return await BackendApi.get(
    `Producto?textoBusqueda=${textoBusqueda}&categoriaId=${categoriaId}`
  );
};

export const EliminarProducto = async id => {
  return await BackendApi.delete(`Producto/${id}`);
};

export const CrearProducto = async (producto, imagenes, imagenesSrc) => {
  const formData = new FormData();
  for (let i = 0; i < imagenes.length; i++) {
    formData.append(`imagenes`, imagenes[i]);
  }

  const json = JSON.stringify(producto);
  const blob = new Blob([json], {
    type: "application/json"
  });
  formData.append("producto", blob);

  const json2 = JSON.stringify(imagenesSrc);
  const blob2 = new Blob([json2], {
    type: "application/json"
  });
  formData.append("imagenesSrc", blob2);

  return await BackendApi.post(`Producto`, formData, {
    baseURL: process.env.REACT_APP_BACKEND_API_URL,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const ModificarProducto = async (producto, imagenes, imagenesSrc) => {
  const formData = new FormData();
  for (let i = 0; i < imagenes.length; i++) {
    formData.append(`imagenes`, imagenes[i]);
  }

  const json = JSON.stringify(producto);
  const blob = new Blob([json], {
    type: "application/json"
  });
  formData.append("producto", blob);

  const json2 = JSON.stringify(imagenesSrc);
  const blob2 = new Blob([json2], {
    type: "application/json"
  });
  formData.append("imagenesSrc", blob2);

  return BackendApi.put(`Producto`, formData, {
    baseURL: process.env.REACT_APP_BACKEND_API_URL,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const ActualizarPassword = async data => {
  return await BackendApi.post(`Login/actualizar-password`, data);
};
