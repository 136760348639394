import { Alert, Snackbar, Stack } from "@mui/material";
import React, { useState } from "react";
import Slide, { SlideProps } from "@mui/material/Slide";

export const useSnackbar = () => {
  const SlideTransition = (props: SliderProps) => {
    return <Slide {...props} direction="down" />;
  };

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success"
  });

  const openSuccessSnackbar = message => {
    setSnackbar({ open: true, message, severity: "success" });
  };

  const openErrorSnackbar = message => {
    setSnackbar({ open: true, message, severity: "error" });
  };

  const openInfoSnackbar = message => {
    setSnackbar({ open: true, message, severity: "info" });
  };

  return {
    openSuccessSnackbar,
    openErrorSnackbar,
    openInfoSnackbar,
    SnackbarComponent: (
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        TransitionComponent={SlideTransition}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    )
  };
};
