import React, { Component, useEffect, useState } from "react";
import {
  TextField,
  Button,
  Card,
  CardMedia,
  CardContent,
  Typography,
  capitalize
} from "@mui/material";
import { MdClear, MdClose, MdSearch } from "react-icons/md";
import "./../../styles/agrobolsas.scss";
import { camelize } from "../../utils/utils";
import { ObtenerProductos } from "../../services/HttpBaseService";
import { useRequestHandler } from "../../utils/useRequestHandler";
import { useTypeThenRun } from "../../utils/useTypeThenRun";
import ProductoModal from "./productoModal";

const ListadoProductos = ({
  textoBusquedaParam,
  categoriaSelectedParam,
  categorias,
  productosRef
}) => {
  const sendRequest = useRequestHandler();
  const [textoBusqueda, setTextoBusqueda] = useState("");
  const [textoBusquedaTmp, setTextoBusquedaTmp] = useState("");
  const [productos, setProductos] = useState([]);
  const [categoriaSelected, setCategoriaSelected] = useState();
  const [productoModal, setProductoModal] = useState();
  const [imagenMostrando, setImagenMostrando] = useState();
  useEffect(() => {
    setTextoBusquedaTmp(textoBusquedaParam);
    setTextoBusqueda(textoBusquedaParam);
  }, [textoBusquedaParam]);

  useEffect(() => {
    setCategoriaSelected(categoriaSelectedParam);
  }, [categoriaSelectedParam]);

  useEffect(() => {
    sendRequest(
      ObtenerProductos,
      [textoBusqueda, categoriaSelected?.id],
      data => {
        setProductos(data?.data);
      }
    );
  }, [textoBusqueda, categoriaSelected]);

  useEffect(() => {
    productosRef.current?.scrollIntoView();
  }, [categoriaSelected]);

  const handleClose = () => {
    setShow(false);
    setProductoModal();
  };
  const handleShow = producto => {
    setShow(true);
    setProductoModal(producto);
  };

  useTypeThenRun(textoBusquedaTmp, () => setTextoBusqueda(textoBusquedaTmp));
  const [show, setShow] = useState(false);
  return (
    <div>
      <div className="prodcutos-header">
        <div class="container">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="title">
                  <h2>
                    Nuestros <strong class="black"> productos</strong>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex row w-100 mb-4 justify-content-center">
          <TextField
            className="buscador"
            label="Buscar productos"
            value={textoBusquedaTmp}
            onChange={e => setTextoBusquedaTmp(e.target.value)}
          />

          <div className="col-auto btn-clean p-0">
            <Button
              title="limpiar búsqueda"
              variant="outlined"
              onClick={() => {
                setTextoBusquedaTmp("");
                setCategoriaSelected();
              }}
            >
              <MdClear />
            </Button>
          </div>
        </div>
      </div>

      <div className="d-flex row w-100 m-auto">
        <div className="col-auto filtros-categorias-wrapper">
          <div class="filtros-categorias">
            {categorias &&
              categorias.length > 0 &&
              categorias.map(x => (
                <div
                  className={`filtros-categorias-item ${
                    x.id == categoriaSelected?.id
                      ? "active"
                      : categoriaSelected
                      ? "opaco-7"
                      : ""
                  }
                  `}
                  onClick={() => {
                    setCategoriaSelected(
                      x.id == categoriaSelected?.id ? null : x
                    );
                  }}
                >
                  <div className="d-flex row">
                    <div className="col">
                      <h3>{x.nombre}</h3>
                    </div>
                    {x.id == categoriaSelected?.id && (
                      <div
                        className="btn-clean-categoria"
                        onClick={e => {
                          setCategoriaSelected();
                          e.stopPropagation();
                        }}
                      >
                        <MdClear size={24} title="Sacar filtro" />
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="col row listado-productos">
          {productos?.length == 0 ? (
            <h3>No hay resultados que coincidan con la búsqueda ingresada</h3>
          ) : (
            productos.map((producto, index) => (
              <div key={index}>
                <Card
                  onClick={() => handleShow(producto)}
                  className="card-productos m-2 col"
                >
                  <CardMedia
                    image={
                      producto.imagenes[0]?.imagenPath ?? "images/no-image.jpg"
                    }
                    title={producto.nombre}
                    className={"producto-img"}
                    component="img"
                    alt={producto.nombre}
                  />
                  <CardContent>
                    <Typography component="div">
                      <h4 className="producto-nombre">
                        {camelize(producto.nombre)}
                      </h4>
                    </Typography>
                    <Typography className="producto-categoria" component="div">
                      <span>{capitalize(producto.categoriaNombre ?? "")}</span>
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            ))
          )}
        </div>
      </div>
      {productoModal && (
        <ProductoModal
          productoModal={productoModal}
          show={show}
          handleClose={handleClose}
        />
      )}
    </div>
  );
};

export default ListadoProductos;
