import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { useRequestHandler } from "../../utils/useRequestHandler";
import { ActualizarPassword } from "../../services/HttpBaseService";
import { showErrorMessage, showSuccessMessage } from "../../redux/actions";

export const Password = () => {
  const [passwordActual, setPasswordActual] = useState("");
  const [passwordNueva, setPasswordNueva] = useState("");
  const [passwordNueva2, setPasswordNueva2] = useState("");
  const sendRequest = useRequestHandler();
  const dispatch = useDispatch();

  const handleGuardar = () => {
    if (passwordNueva != passwordNueva2) {
      dispatch(showErrorMessage("Las contraseñas nuevas no coinciden"));
    } else {
      sendRequest(
        ActualizarPassword,
        [
          {
            passwordActual,
            passwordNueva,
            passwordNueva2
          }
        ],
        () => {
          dispatch(showSuccessMessage("Contraseña actualizada correctamente"));
          setPasswordActual("");
          setPasswordNueva("");
          setPasswordNueva2("");
        }
      );
    }
  };

  return (
    <>
      <>
        <div className="justify-content-around ">
          <div className="d-flex row justify-content-between">
            <div className="col-auto m-2">
              <h2>Cambiar contraseña</h2>
            </div>
          </div>
          <hr></hr>
          <div className="justify-content-center w-auto d-flex row">
            <div className="col-auto">
              <div className="col m-2">
                <TextField
                  type="password"
                  className="text-field"
                  size="large"
                  label="Contraseña actual"
                  value={passwordActual}
                  onChange={e => setPasswordActual(e.target.value)}
                />
              </div>
              <div className="col m-2">
                <TextField
                  type="password"
                  className="text-field"
                  size="large"
                  label="Contraseña nueva"
                  value={passwordNueva}
                  onChange={e => setPasswordNueva(e.target.value)}
                />
              </div>
              <div className="col m-2">
                <TextField
                  type="password"
                  className="text-field"
                  size="large"
                  label="Confirmar contraseña nueva"
                  value={passwordNueva2}
                  onChange={e => setPasswordNueva2(e.target.value)}
                />
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="d-flex row justify-content-center w-auto">
            <Button variant={"contained"} onClick={handleGuardar}>
              Confirmar
            </Button>
          </div>
        </div>
      </>
    </>
  );
};

export default Password;
