import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControlLabel,
  Switch
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch } from "react-redux";
import {
  CrearProducto,
  EliminarProducto,
  ModificarProducto,
  ObtenerCategorias,
  ObtenerProductos
} from "../../services/HttpBaseService";
import { useRequestHandler } from "../../utils/useRequestHandler";
import { showErrorMessage } from "../../redux/actions";
import { useTypeThenRun } from "../../utils/useTypeThenRun";
import { MdClear, MdDelete } from "react-icons/md";
import { ContentState, Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  ContentState as CS,
  convertFromHTML
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

const ProductoABM = () => {
  const [productos, setProductos] = useState([]);
  const [agregando, setAgregando] = useState(false);
  const [nombre, setNombre] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [editando, setEditando] = useState(false);
  const [idEditando, setIdEditando] = useState(0);
  const [categoriaId, setCategoriaId] = useState(0);
  const [categoriaFiltroId, setCategoriaFiltroId] = useState(0);
  const [activo, setActivo] = useState(true);
  const sendRequest = useRequestHandler();
  const dispatch = useDispatch();
  const [categorias, setCategorias] = useState([]);
  const [imagenesFiles, setImagenesFiles] = useState([]);
  const [imgPreviewSrc, setImgPreviewSrc] = useState("");
  const [textoBusqueda, setTextoBusqueda] = useState("");
  const [textoBusquedaTmp, setTextoBusquedaTmp] = useState("");
  const [imagenes, setImagenes] = useState([]);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = editorState => {
    setEditorState(editorState);
    setDescripcion(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  useTypeThenRun(textoBusquedaTmp, () => setTextoBusqueda(textoBusquedaTmp));

  const refrescarGrilla = () => {
    sendRequest(ObtenerProductos, [textoBusqueda, categoriaFiltroId], data => {
      setProductos(data?.data);
    });
  };

  const limpiarForm = () => {
    setNombre("");
    setDescripcion("");
    setActivo(true);
    setCategoriaId(0);
    setImagenesFiles([]);
    setImagenes([]);
    setEditorState(EditorState.createEmpty());
    setImgPreviewSrc("");
  };

  useEffect(() => {
    sendRequest(ObtenerCategorias, [], data => {
      setCategorias(data?.data);
    });
  }, []);

  useEffect(() => {
    refrescarGrilla();
  }, [categoriaFiltroId, textoBusqueda]);

  const eliminarProducto = id => {
    sendRequest(EliminarProducto, [id], refrescarGrilla);
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 5 },
    { field: "nombre", headerName: "Nombre", flex: 80 },

    {
      field: "actionDelete",
      headerName: "",
      sortable: false,
      flex: 1,
      align: "right",
      renderCell: params => {
        const onClick = e => {
          if (window.confirm("¿Confirmás eliminar el producto?")) {
            eliminarProducto(params?.row?.id);
          }
          refrescarGrilla();
        };

        return (
          <DeleteIcon
            size={28}
            className="action-icon"
            title="Eliminar"
            onClick={onClick}
          />
        );
      }
    },
    {
      field: "actionEdit",
      headerName: "",
      sortable: false,
      flex: 1,
      align: "right",
      renderCell: params => {
        const onClick = e => {
          setAgregando(true);
          setEditando(true);
          setIdEditando(params.row.id);
          setNombre(params.row.nombre);
          setDescripcion(params.row.descripcion || "");
          setActivo(params.row.activo);
          setCategoriaId(params.row.categoriaId || 0);
          setImagenes(params.row.imagenes);
          setImgPreviewSrc(params.row.imagenes?.[0]?.imagenPath);
          const blocksFromHTML = convertFromHTML(params.row.descripcion);
          const state = CS.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
          );
          setEditorState(EditorState.createWithContent(state));
        };

        return (
          <EditIcon
            size={28}
            className="action-icon"
            title="Editar"
            onClick={onClick}
          />
        );
      }
    }
  ];

  const handleGuardar = () => {
    if (editando) {
      sendRequest(
        ModificarProducto,
        [
          {
            id: idEditando,
            nombre,
            descripcion,
            categoriaId,
            activo
          },
          imagenesFiles.map(x => x.file),
          imagenes.map(x => x.imagenPath)
        ],
        data => {
          limpiarForm();
          setEditando(false);
          setAgregando(false);
          refrescarGrilla();
        }
      );
    } else {
      sendRequest(
        CrearProducto,
        [
          {
            nombre,
            descripcion,
            categoriaId,
            activo
          },
          imagenesFiles.map(x => x.file),
          imagenes.map(x => x.imagenPath)
        ],
        () => {
          limpiarForm();
          setEditando(false);
          setAgregando(false);
          refrescarGrilla();
        }
      );
    }
  };

  const handleChange = event => {
    const files = Array.from(event.target.files);
    const [file] = files;
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

    if (!allowedExtensions.exec(file.name)) {
      dispatch(showErrorMessage("Tipo de archivo no válido"));
      return false;
    }

    var reader = new FileReader();
    reader.onload = function(e) {
      setImgPreviewSrc(e.target.result);
      setImagenes([...imagenes, { imagenPath: e.target.result }]);
      setImagenesFiles([
        ...imagenesFiles,
        { file: file, imagenPath: e.target.result }
      ]);
    };
    reader.readAsDataURL(file);
  };

  return (
    <div>
      {!agregando ? (
        <>
          <div className="d-flex row m-2 justify-content-between">
            <div className="col-auto">
              <h2>Productos</h2>
            </div>
            <div className="col-auto">
              <TextField
                className="text-field"
                label="Buscar productos"
                value={textoBusquedaTmp}
                onChange={e => setTextoBusquedaTmp(e.target.value)}
              />
            </div>
            <div className="col-auto">
              <Select
                size="small"
                className="text-field"
                placeholder="Filtrar por categoría"
                value={categoriaFiltroId}
                onChange={e => setCategoriaFiltroId(e.target.value)}
              >
                <MenuItem key={0} value={0}>
                  {"Todas las categorías"}
                </MenuItem>
                {categorias?.map(c => (
                  <MenuItem key={c.id} value={c.id}>
                    {c.nombre}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="col-auto btn-clean p-0">
              <Button
                title="limpiar filtros"
                variant="outlined"
                onClick={() => {
                  setTextoBusquedaTmp("");
                  setCategoriaFiltroId(0);
                }}
              >
                <MdClear />
              </Button>
            </div>
            <div className="col text-right">
              <Button
                variant={"contained"}
                onClick={() => {
                  setAgregando(true);
                }}
              >
                Agregar
              </Button>
            </div>
          </div>
          <hr></hr>
          <div
            className="row m-2 justify-content-center"
            style={{ width: "100%" }}
          >
            {productos?.length > 0 ? (
              <DataGrid
                hideFooterPagination
                rows={productos}
                columns={columns}
                checkboxSelection={false}
              />
            ) : (
              <h5>No se encontraron resultados</h5>
            )}
          </div>
        </>
      ) : (
        <div className="justify-content-around ">
          <h3>{editando ? "Modificar Producto" : "Nuevo Producto"}</h3>
          <hr></hr>
          <div className="justify-content-center w-auto d-flex row">
            <div className="col">
              <div className="col m-2">
                <TextField
                  className="w-100"
                  size="large"
                  label="Nombre"
                  value={nombre}
                  onChange={e => setNombre(e.target.value)}
                  multiline
                  rows={2}
                />
              </div>
              <div className="col m-2">
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="editor-wysiwyg"
                  editorClassName="editorClassName"
                  onEditorStateChange={onEditorStateChange}
                />
              </div>
              <div className="col m-2">
                <Select
                  size="small"
                  className="w-100"
                  placeholder="Categoría de productos"
                  value={categoriaId}
                  onChange={e => setCategoriaId(e.target.value)}
                >
                  <MenuItem key={0} value={0}>
                    {"Sin categoría"}
                  </MenuItem>
                  {categorias?.map(c => (
                    <MenuItem key={c.id} value={c.id}>
                      {c.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </div>

              <div className="col m-2">
                <FormControlLabel
                  control={
                    <Switch
                      checked={activo}
                      onChange={e => setActivo(e.target.checked)}
                    />
                  }
                  label="Activo"
                />
              </div>
            </div>
            <div className="col">
              <div className="col file-upload ">
                <div className="row px-4">
                  <label
                    className="col d-flex justify-content-between"
                    htmlFor="upload-photo"
                  >
                    <input
                      style={{ display: "none" }}
                      id="upload-photo"
                      name="upload-photo"
                      type="file"
                      onChange={handleChange}
                    />
                    <Button
                      color="secondary"
                      variant="contained"
                      component="span"
                    >
                      Cargar imagen
                    </Button>
                  </label>
                </div>
                {imgPreviewSrc && (
                  <div id="imagePreview" className="col text-center">
                    <img src={imgPreviewSrc} className="img-preview"></img>
                    <MdDelete
                      className="remove-img"
                      size={20}
                      onClick={() => {
                        if (window.confirm("¿Confirmás eliminar esta foto?")) {
                          setImgPreviewSrc(
                            imagenes.find(x => x.imagenPath != imgPreviewSrc)
                              ?.imagenPath
                          );
                          setImagenes(
                            imagenes.filter(x => x.imagenPath != imgPreviewSrc)
                          );
                          setImagenesFiles(
                            imagenesFiles.filter(
                              x => x.imagenPath != imgPreviewSrc
                            )
                          );
                        }
                      }}
                    />
                  </div>
                )}

                <div className="modal-img-viewer pt-2">
                  <div className="modal-img-preview-wrapper">
                    {imagenes?.length > 0 &&
                      imagenes?.map((img, index) => (
                        <div
                          onClick={() => setImgPreviewSrc(img.imagenPath)}
                          className={`modal-img-preview ${
                            img.imagenPath == imgPreviewSrc ? "active" : ""
                          }`}
                        >
                          <img
                            src={img.imagenPath ?? "images/no-image.jpg"}
                          ></img>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="d-flex row justify-content-center w-auto">
            <div className="col text-left">
              <Button
                variant={"outlined"}
                onClick={() => {
                  setEditando(false);
                  setAgregando(false);
                  limpiarForm();
                }}
              >
                Cancelar
              </Button>
            </div>
            <div className="col text-right">
              <Button variant={"contained"} onClick={handleGuardar}>
                Guardar
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductoABM;
