import React, { useEffect, useState } from "react";
import {
  MenuItem,
  Select,
  TextField,
  Button,
  FormControlLabel,
  Switch
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { CiWarning } from "react-icons/ci";
import { useDispatch } from "react-redux";

import {
  CrearCarrusel,
  EliminarCarrusel,
  ModificarCarrusel,
  ObtenerCategorias,
  ObtenerElementosCarrusel
} from "../../services/HttpBaseService";
import { useRequestHandler } from "../../utils/useRequestHandler";
import { showErrorMessage } from "../../redux/actions";

export const CarruselABM = () => {
  const [elementos, setElementos] = useState([]);
  const [agregando, setAgregando] = useState(false);
  const [titulo, setTitulo] = useState("");
  const [subtitulo, setSubtitulo] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [editando, setEditando] = useState(false);
  const [idEditando, setIdEditando] = useState(0);
  const [orden, setOrden] = useState(0);
  const [imagen, setImagen] = useState();
  const [imgPreviewSrc, setImgPreviewSrc] = useState("");
  const sendRequest = useRequestHandler();
  const dispatch = useDispatch();
  const [categoriaId, setCategoriaId] = useState(0);
  const [categorias, setCategorias] = useState([]);

  const refrescarGrilla = () => {
    sendRequest(ObtenerElementosCarrusel, [], data => {
      setElementos(data?.data);
      setOrden(
        data?.data.reduce((max, objeto) => {
          return objeto.orden > max ? objeto.orden : max;
        }, 0) + 1
      );
    });
  };

  const limpiarForm = () => {
    setTitulo("");
    setSubtitulo("");
    setDescripcion("");
    setImagen({});
    setImgPreviewSrc("");
    setCategoriaId(0);
  };

  useEffect(() => {
    refrescarGrilla();
    sendRequest(ObtenerCategorias, [], data => {
      setCategorias(data?.data);
    });
  }, []);

  const eliminarCarrusel = id => {
    sendRequest(EliminarCarrusel, [id], refrescarGrilla);
  };

  const columns = [
    {
      field: "actionDelete",
      headerName: "",
      sortable: false,
      flex: 1,
      align: "right",
      renderCell: params => {
        const onClick = e => {
          if (window.confirm("¿Confirmás eliminar el elemento del carrusel?")) {
            eliminarCarrusel(params?.row?.id);
          }
          refrescarGrilla();
        };

        return (
          <DeleteIcon
            size={28}
            className="action-icon"
            title="Eliminar"
            onClick={onClick}
          />
        );
      }
    },
    {
      field: "actionEdit",
      headerName: "",
      sortable: false,
      flex: 1,
      align: "right",
      renderCell: params => {
        const onClick = e => {
          setAgregando(true);
          setEditando(true);
          setIdEditando(params.row.id);
          setTitulo(params.row.titulo);
          setSubtitulo(params.row.subtitulo);
          setDescripcion(params.row.descripcion);
          setOrden(params.row.orden);
          setImgPreviewSrc(params.row.imagenPath);
          setCategoriaId(params.row.categoriaId ?? 0);
        };

        return (
          <EditIcon
            size={28}
            className="action-icon"
            title="Editar"
            onClick={onClick}
          />
        );
      }
    },
    { field: "orden", headerName: "Orden", flex: 5 },
    { field: "titulo", headerName: "Título", flex: 80 },
    {
      field: "imagenPath",
      headerName: "Imagen",
      flex: 50,
      height: "118px",
      renderCell: params => {
        return (
          <img
            style={{ width: "288px", height: "118px" }}
            src={params?.row?.imagenPath}
          ></img>
        );
      }
    }
  ];

  const handleGuardar = () => {
    if (editando) {
      sendRequest(
        ModificarCarrusel,
        [
          {
            id: idEditando,
            titulo,
            subtitulo,
            descripcion,
            categoriaId: parseInt(categoriaId),
            orden: parseInt(orden)
          },
          imagen
        ],
        data => {
          limpiarForm();
          setEditando(false);
          setAgregando(false);
          refrescarGrilla();
        }
      );
    } else {
      sendRequest(
        CrearCarrusel,
        [
          {
            titulo,
            subtitulo,
            descripcion,
            categoriaId: parseInt(categoriaId),
            orden: parseInt(orden)
          },
          imagen
        ],
        () => {
          limpiarForm();
          setEditando(false);
          setAgregando(false);
          refrescarGrilla();
        }
      );
    }
  };

  const handleChange = event => {
    const files = Array.from(event.target.files);
    const [file] = files;
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

    if (!allowedExtensions.exec(file.name)) {
      dispatch(showErrorMessage("Tipo de archivo no válido"));
      setImagen({});
      return false;
    }
    setImagen(file);
    var reader = new FileReader();
    reader.onload = function(e) {
      setImgPreviewSrc(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <>
        {!agregando ? (
          <>
            <div className="d-flex row m-2 justify-content-between">
              <div className="col-4">
                <h2>Elementos del carrusel</h2>
              </div>

              <div>
                <Button
                  variant={"contained"}
                  onClick={() => {
                    setAgregando(true);
                  }}
                >
                  Agregar
                </Button>
              </div>
            </div>
            <hr></hr>
            <div
              className="row m-2 justify-content-center"
              style={{ width: "100%" }}
            >
              {elementos?.length > 0 ? (
                <DataGrid
                  hideFooterPagination
                  rowHeight={138}
                  rows={elementos}
                  columns={columns}
                  checkboxSelection={false}
                />
              ) : (
                <h5 className="">No se encontraron resultados</h5>
              )}
            </div>
          </>
        ) : (
          <div className="justify-content-around ">
            <div className="d-flex row justify-content-between">
              <div className="col m-2">
                <h3>
                  {editando
                    ? "Modificar elemento del carrusel"
                    : "Nuevo elemento del carrusel"}
                </h3>
              </div>
            </div>
            <hr></hr>
            <div className="justify-content-center w-auto d-flex row">
              <div className="col">
                <div className="col m-2">
                  <TextField
                    className="w-100"
                    size="large"
                    label="Título"
                    value={titulo}
                    onChange={e => setTitulo(e.target.value)}
                    multiline
                    rows={2}
                  />
                </div>
                <div className="col m-2">
                  <TextField
                    className="w-100"
                    size="large"
                    label="Subtítulo"
                    value={subtitulo}
                    onChange={e => setSubtitulo(e.target.value)}
                    multiline
                    rows={2}
                  />
                </div>
                <div className="col m-2">
                  <TextField
                    className="w-100"
                    size="large"
                    label="Descripcion"
                    value={descripcion}
                    onChange={e => setDescripcion(e.target.value)}
                    multiline
                    rows={5}
                  />
                </div>
                <div className="col m-2">
                  <Select
                    size="small"
                    className="w-100"
                    placeholder="Categoría de productos"
                    value={categoriaId}
                    onChange={e => setCategoriaId(e.target.value)}
                  >
                    <MenuItem key={0} value={0}>
                      {"Sin categoría"}
                    </MenuItem>
                    {categorias?.map(c => (
                      <MenuItem key={c.id} value={c.id}>
                        {c.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="col m-2">
                  <TextField
                    type="number"
                    className="text-field"
                    size="large"
                    label="Orden"
                    value={orden}
                    onChange={e => setOrden(e.target.value)}
                  />
                </div>
              </div>
              <div className="col">
                <div className="col file-upload ">
                  <div className="col mb-2">
                    <h2>Características recomendadas</h2>
                    <h4>Relación de aspecto 16:9</h4>
                    <h4>Resolución mínima 1280px x 720px</h4>
                    <p style={{ color: "#dc3545" }}>
                      <CiWarning
                        class="icono-warning"
                        color="#dc3545"
                        size={24}
                      />
                      Es muy importante cargar imágenes que cumplan con estas
                      proporciones para que no se deformen al ser mostradas en
                      el carrusel
                    </p>
                  </div>
                  <div className="row px-4">
                    <label
                      className="col d-flex justify-content-between"
                      htmlFor="upload-photo"
                    >
                      <input
                        style={{ display: "none" }}
                        id="upload-photo"
                        name="upload-photo"
                        type="file"
                        onChange={handleChange}
                      />
                      <Button
                        color="secondary"
                        variant="contained"
                        component="span"
                      >
                        Cargar imagen
                      </Button>
                      <span className="m-2">{imagen?.name}</span>
                    </label>
                  </div>
                  {imgPreviewSrc && (
                    <div id="imagePreview" className="col text-center">
                      <img src={imgPreviewSrc} className="img-preview"></img>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <hr></hr>
            <div className="d-flex row justify-content-center w-auto">
              <div className="col text-left">
                <Button
                  variant={"outlined"}
                  onClick={() => {
                    setEditando(false);
                    setAgregando(false);
                    limpiarForm();
                  }}
                >
                  Cancelar
                </Button>
              </div>
              <div className="col text-right">
                <Button variant={"contained"} onClick={handleGuardar}>
                  Guardar
                </Button>
              </div>
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default CarruselABM;
