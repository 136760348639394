import { Provider } from "react-redux";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { store } from "./redux/store";
import SnackbarWrapper from "./components/common/SnackbarWrapper";
import BackdropWrapper from "./components/common/BackdropWrapper";
import Admin from "./components/admin/admin";
import HomePage from "./components/home/homePage";

function App() {
  return (
    <Provider store={store}>
      <SnackbarWrapper />
      <BackdropWrapper />
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/admin" element={<Admin />} />
          <Route exact path="/mallasombra" element={<HomePage />} />
          <Route exact path="/bolsaplatillera" element={<HomePage />} />
          <Route exact path="/bolsaarpillera" element={<HomePage />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
